.footer {
    align-items: center;
    background-color: var(--color-6);
    background-blend-mode: luminosity;
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
    color: var(--text-6);
    display: flex;
    flex-direction: column;
    height: var(--meta-height);
    justify-content: center;
    overflow: hidden;
    position: relative;
    transition: background-blend-mode 500ms;

    @media print {
        background-color: #fff;
        background-image: none!important;
        border-top: 1px solid #000;
        color: #000;
    }

    &:hover {
        background-blend-mode: normal;
    }

    &:hover &, &:active &, &:focus &, &:focus-within & {
        &__sauce {
            background-color: var(--color-6);
            opacity: 1;
        }
    }

    &__make {
        align-items: center;
        display: flex;
    }

    &__legal {
      @media print {
        display: none;
      }
    }

    &__link {
        color: inherit;
        margin: 0 4px;
        text-decoration: none;

        &:hover, &:active, &:focus {
            color: inherit;
            text-decoration: underline;
        }

        &--sauce {
            font-style: normal;
            font-size: 0.9em;
            margin: 0;
        }
    }

    &__sauce {
        background-color: transparent;
        bottom: 0;
        font-style: italic;
        position: absolute;
        padding: 1px 2px;
        padding-right: 6px;
        opacity: 0.75;
        transition: opacity 100ms, background-color 100ms;
        right: 0;
        font-size: unset;
        letter-spacing: -1.5px;
        user-select: all;

        @media print {
            display: none;
        }
    }
}

// NOTE Having some difficulty confirming if `@media speech` actually works.
@media speech {
    .icon__icon {
        display: none;
    }
    .icon__label {
        display: unset;
    }
}
@media not speech {
    .icon__icon {
        display: unset;
    }
    .icon__label {
        display: none;
    }
}
