.icon {
  &--nopointer {
    pointer-events: none;
  }
  &--spin {
    animation: spin 2s infinite forwards linear;

    @keyframes spin {
      from {
        rotate: 0deg;
      }
      to {
        rotate: 360deg;
      }
    }

    &--pause {
      animation-play-state: paused;
    }
  }

  &--blink {
    animation: blink 1s infinite forwards steps(2, start);

    @keyframes blink {
      from {
        visibility: visible;
      }
      to {
        visibility: hidden;
      }
    }
  }
}
