.alert {
    background-color: var(--color-4);
    color: var(--text-4);

    padding: 0.25rem 1rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 100%;
        display: block;
        height: 0.5rem;
        width: 100%;
    }

    &--info {
        &:before {
            background: #2196F3;
        }
    }

    &--warning {
        &:before {
            background: repeating-linear-gradient(45deg, #FFB300 0px, #FFB300 16px, #FF6F00 16px, #FF6F00 32px);
        }
    }

    &--danger {
        &:before {
            background: repeating-linear-gradient(45deg, #E53935 0px, #E53935 16px, #B71C1C 16px, #B71C1C 32px);
        }
    }
}
