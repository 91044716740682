.loadscreen {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &__message {
    position: relative;
    width: 48px;
    height: 48px;
    display: inline-block;
  }

  &__icon {
    position: absolute;

    &--inner {
      translate: 25% 25%;
    }

    &--outer {
      translate: -12.5% -12.5%;
    }
  }
}
