.nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-x: auto;
    padding: 2px 5%;
    white-space: nowrap;

    @media print {
        display: none;
    }

    &__group {
        display: flex;
        flex-direction: row;
        margin-right: 20%;
    }

    &__item {
        color: inherit;
        margin: 0 8px;
        text-decoration: none;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover, &:active, &:focus {
            color: inherit;
            text-decoration: underline;
        }
    }
}
