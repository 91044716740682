.button {
  background-color: var(--color-6);
  border: 0.15rem solid var(--color-6);
  color: var(--text-6);
  cursor: pointer;
  display: inline-flex;
  font: inherit;
  margin: 0 0.3rem;
  padding: 0.3rem 1rem;
  text-decoration: none;
  transition: outline 50ms cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:focus {
    outline: 0.15rem solid var(--color-9);
  }

  &--alt {
    background-color: #fff;
    color: #000;
  }

  &--link {
    text-decoration: underline;
  }

  &--disabled {
    background-color: #ccc;
    color: #000;
    cursor: not-allowed;

    &:focus {
      outline: none;
    }
  }

  &--mini {
    margin: 0 0.2rem;
    padding: 0.15rem 0.5rem;
    font-size: 0.8rem;
  }
}
