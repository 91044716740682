.tags {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__search {
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-template-rows: 1fr [datalist]0;
    width: 100%;
  }

  &__datalist {
    grid-row: datalist;
  }

  &__searchinput {
    box-sizing: border-box;
    border: 2px solid var(--color-9);
    font-family: inherit;
    font-size: inherit;
    display: inline-block;
    margin: 0;
    padding: 4px 4px;
    flex-grow: 1;
  }

  &__item {
    align-items: center;
    background-color: var(--color-1);
    border: 0.15rem solid var(--color-2);
    color: var(--text-1);
    display: inline-flex;
    padding: 1px 4px;
    margin: 0 4px;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__tagged {
    text-decoration: none;
    color: #000;

    &:hover {
      text-decoration: underline;
    }
  }

  &__link {
    @extend .tags__tagged;
    margin-left: 0.5rem;
    align-content: center;
    display: inline-flex;
  }

  &__remove {
    background: none;
    border: none;
    border-radius: 0;
    cursor: pointer;
    padding: 0;
    margin: 0;
    margin-left: 0.5rem;
    width: 1rem;
    height: 1rem;
  }
}
