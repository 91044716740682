.main {
  box-sizing: border-box;
  min-height: calc(100vh - var(--meta-height) * 2);

  --main-width: 80vw;
  --main-margin-width: calc((100vw - var(--main-width)) / 2);

  position: relative;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: var(--main-margin-width) [main]var(--main-width) var(--main-margin-width);

  @include layout('md') {
    --main-width: 95vw;
  }

  @include layout('sm') {
    --main-width: 99vw;
  }

  @media print {
    min-height: unset;
  }

  &--error {
    font-family: "Roboto Mono", monospace;
  }

  &__section {
    grid-column: main;
    width: var(--main-width);
  }
}
