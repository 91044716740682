.pagination {
  display: grid;
  grid-template: 1fr / repeat(9, minmax(max-content, 3.5rem));
  place-items: center;
  justify-content: center;

  &__item {
    border: 0.15rem var(--color-6) solid;
    box-sizing: content-box;
    color: #000;
    cursor: pointer;
    padding: 0.15rem 0.15rem;
    text-decoration: none;
    user-select: none;
    min-width: 2rem;
    height: 2rem;
    font-size: 1rem;

    &--selected {
      font-weight: bold;
      text-decoration: underline;
      text-decoration-thickness: 0.1rem;
    }
  }
}
