.modal {
  background: #fff;
  padding: 2rem;
  box-sizing: border-box;
  border: 0.2rem #000 solid;
  max-height: calc(100% - var(--meta-height) * 2);
  overflow: auto;
  width: 75%;

  @include layout('md') {
    width: 90%;
  }

  &--small {
    width: 50%;

    @include layout('sm') {
      width: 75%;
    }
  }

  &__top {
    position: relative;
    top: -2rem;
  }
}
