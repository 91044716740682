$breakpoints: (
  sm: 575px,
  md: 750px,
  lg: 975px,
  xl: 1200px
);

@mixin layout($size) {
  @if map-has-key($map: $breakpoints, $key: $size) {
      @media (max-width: map-get($map: $breakpoints, $key: $size)) {
          @content;
      }
  } @else {
      @error "Invalid size: #{$size}";
  }
}
