.grid {
  display: grid;

  &--1 {
    grid-template-columns: 1fr;
  }

  &--12 {
    grid-template-columns: 1fr 2fr;

    @include layout('md') {
      grid-template-columns: 1fr;
    }
  }

  &--102 {
    grid-template-columns: 1fr 0fr 2fr;

    @include layout('md') {
      grid-template-columns: 1fr;
    }
  }

  &--11 {
    grid-template-columns: 1fr 1fr;

    @include layout('md') {
      grid-template-columns: 1fr;
    }
  }

  &--112 {
    grid-template-columns: 1fr 1fr 2fr;

    @include layout('md') {
      grid-template-columns: 1fr;
    }
  }

  &--21 {
    // NOTE This likely misaligns with 1fr 1fr 1fr because there of an uneven amount of grid gaps
    grid-template-columns: 2fr 1fr;

    @include layout('md') {
      grid-template-columns: 1fr;
    }
  }

  &--111 {
    grid-template-columns: 1fr 1fr 1fr;

    @include layout('md') {
      grid-template-columns: 1fr;
    }
  }

  &--1111 {
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @include layout('md') {
      grid-template-columns: 1fr;
    }
  }

  &--11111 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    @include layout('md') {
      grid-template-columns: 1fr;
    }
  }
}
