@import "layout";

:root,
:root[data-theme="purple"] {
  --color-05: #EDE7F6;
  --text-05: #000;
  --color-1: #D1C4E9;
  --text-1: #000;
  --color-2: #B39DDB;
  --text-2: #000;
  --color-3: #9575CD;
  --text-3: #fff;
  --color-4: #7E57C2;
  --text-4: #fff;
  // base
  --color-5: #673AB7;
  --text-5: #fff;
  --color-6: #5E35B1;
  --text-6: #fff;
  --color-7: #512DA8;
  --text-7: #fff;
  --color-8: #4527A0;
  --text-8: #fff;
  --color-9: #311B92;
  --text-9: #fff;

  --select-color-5: #B388FF; // Accent 100
  --select-text-5: #000;

  --main-margin: 10%;
  --main-padding: 1rem;
  --meta-height: 6rem;

  @include layout('sm') {
    --main-margin: 0.5%;
  }
}

:root[data-theme="red"] {
  --color-05: #FFEBEE;
  --text-05: #000;
  --color-1: #FFCDD2;
  --text-1: #000;
  --color-2: #EF9A9A;
  --text-2: #000;
  --color-3: #E57373;
  --text-3: #000;
  --color-4: #EF5350;
  --text-4: #000;
  // base
  --color-5: #F44336;
  --text-5: #000;
  --color-6: #E53935;
  --text-6: #000;
  --color-7: #D32F2F;
  --text-7: #fff;
  --color-8: #C62828;
  --text-8: #fff;
  --color-9: #B71C1C;
  --text-9: #fff;

  --select-color-5: #FF8A80;
  --select-text-5: #000;
}

:root[data-theme="pink"] {
  --color-05: #FCE4EC;
  --text-05: #000;
  --color-1: #F8BBD0;
  --text-1: #000;
  --color-2: #F48FB1;
  --text-2: #000;
  --color-3: #F06292;
  --text-3: #000;
  --color-4: #EC407A;
  --text-4: #000;
  // base
  --color-5: #E91E63;
  --text-5: #000;
  --color-6: #D81B60;
  --text-6: #fff;
  --color-7: #C2185B;
  --text-7: #fff;
  --color-8: #AD1457;
  --text-8: #fff;
  --color-9: #880E4F;
  --text-9: #fff;

  --select-color-5: #FF8A80;
  --select-text-5: #000;
}

/* ::selection {
    background-color: var(--select-color-5);
    color: var(--select-text-5);
} */

body {
  font-family: "Noto Sans", sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--color-05);
  color: var(--text-05);
}

.body {
  &--lock {
    overflow: hidden;
  }
}

@page {
  margin: 0;
}

@import "flex";
@import "grid";
@import "debug";
@import "header";
@import "footer";
@import "main";
@import "nav";
@import "form";
@import "button";
@import "datalist";
@import "alert";
@import "icon";
@import "pagination";
@import "tags";
@import "list";
@import "modal";
@import "loadscreen";

.message {
  background-color: #dddddd;
  border: 1px solid #cccccc;
  color: #000;
  padding: 4px 16px;
  margin: 2px;

  &--warning {
    background-color: #ff0000;
    border: 1px solid #ee0000;
    color: #fff;
  }
}

.text-button {
  background: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;
  font: inherit;

  &:hover {
    text-decoration: underline;
  }
}

.copyzone {
  cursor: copy;
  border: 2px solid var(--color-1);
  padding: 1em;
  user-select: all;
}
