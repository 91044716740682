.datalist {
  border: 1px #000 solid;
  display: block;
  z-index: 1;

  &--empty {
    display: none;
  }

  &__option {
    padding: 0.2rem 0.2rem;
    user-select: none;
    background-color: #eee;
    cursor: pointer;

    &:hover {
      background-color: var(--color-1);
    }
  }

  &__name {
    user-select: none;
  }

  &__hint {
    margin-left: 1rem;
    user-select: none;
    font-size: 0.9rem;
  }
}
