.list {
  border-collapse: separate;
  overflow: auto;
  display: block;

  &__heading {
    border-bottom: 1px solid var(--color-5);
    position: sticky;
    top: 0;
  }

  &__row {
    margin: 1px;
    transition: 50ms background-color linear;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &:nth-child(2n) {
        background-color: var(--color-2);
        color: var(--text-2);
    }
  }

  &__header {
    border-bottom: 2px solid var(--color-3);
    padding: 1px 4px;
  }

  &__data {
    padding: 1px 4px;
  }

  &__controls {
    white-space: nowrap;
  }
}
