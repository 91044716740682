.header {
    background-color: var(--color-6);
    color: var(--text-6);
    display: flex;
    flex-direction: column;
    height: var(--meta-height);
    justify-content: space-between;

    @media print {
        background-color: #fff;
        border-bottom: 1px solid #000;
        color: #000;
    }

    &__top {
        align-items: center;
        display: flex;
        height: 4rem;
        justify-content: space-between;
        margin: 0 5%;
    }

    &__link {
        align-items: center;
        color: inherit;
        display: inline-flex;
        text-decoration: none;
    }

    &__home {
        align-items: center;
        display: flex;
        justify-content: center;
        text-decoration: none;
    }

    &__logo {
        transform: scale(1.5);
        transform-origin: left;
        pointer-events: none;
    }

    &__watermark {
        color: inherit;
        font-size: 1.5rem;
        pointer-events: none;
        position: relative;
        left: 1rem;
    }

    &__pageName {
        font-size: 1.5rem;
        font-weight: normal;
        margin: 0;
    }
}
