
.form {
  &__section {
    display: grid;
    grid-column-gap: 2rem;
    grid-row-gap: 0;

    @include layout('md') {
      grid-gap: 0;
    }
  }

  &__label {
    display: inline-block;
    width: 100%;

    @include layout('sm') {
      min-width: unset;
    }

    &--inline {
      min-width: unset;
    }
  }

  &__limithint {
    text-align: right;
    font-size: 0.9rem;
  }

  &__error {
    color: #ff0000;

    @include layout('sm') {
      margin-left: unset;
    }
  }

  &__item {
    display: block;
    margin: 16px 0;
    width: 100%;

    @include layout('sm') {
      flex-wrap: wrap;
    }
  }

  &__text, &__date, &__number {
    box-sizing: border-box;
    border: 2px solid var(--color-9);
    font-family: inherit;
    font-size: inherit;
    display: inline-block;
    margin: 0;
    padding: 4px 4px;
    width: 100%;

    &:focus {
      outline: 2px solid var(--color-1);
    }
  }

  &__select {
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    position: relative;
    width: 100%;
    border: 2px solid var(--color-9);
    cursor: pointer;
    background-color: #fff;
    box-sizing: border-box;

    select,
    &::after {
      grid-area: select;
    }

    select {
      appearance: none;
      background-color: transparent;
      border: none;
      padding: 4px 4px;
      margin: 0;
      width: 100%;
      font-family: inherit;
      font-size: inherit;
      cursor: inherit;
      line-height: inherit;
      z-index: 1;
    }

    // Custom arrow
    &:not(&--multiple)::after {
      content: "";
      justify-self: end;
      height: 100%;
      background-color: var(--color-5);
      // clip-path: polygon(6px 10px, 7.41px 8.58px, 12px 13.17px, 16.59px 8.58px, 18px 10px, 12px 16px);
      clip-path: polygon(25% 41.67%, 30.88% 35.75%, 50% 54.88%, 69.13% 35.75%, 75% 41.67%, 50% 66.67%);
      pointer-events: none;
      aspect-ratio: 1/1;
    }

    &--disabled {
      cursor: default;
      background-color: #eee;
      &:not(&--multiple)::after {
        content: unset;
      }
    }
  }

  &__input-number {
    box-sizing: border-box;
    border: 2px solid var(--color-9);
    font-family: inherit;
    font-size: inherit;
    display: inline-block;
    margin: 0;
    padding: 1px 2px;
    flex-grow: 1;
    width: 100%;

    &:focus {
      outline: 2px solid var(--color-1);
    }
  }

  &__textarea {
    border: 2px solid var(--color-9);
    display: inline-block;
    font-family: inherit;
    font-size: inherit;
    height: 16rem;
    margin: 0;
    padding: 1px 2px;
    width: 100%;
    resize: none;
  }

  &__capslock {
    display: none;

    &--visible {
      display: initial;
    }
  }

  &__label-checkbox {
    display: inline-flex;
    align-items: center;
  }

  &__input-checkbox {
    @supports(appearance: none) {
      appearance: none;
      height: 1.5rem;
      width: 1.5rem;

      &:before {
        content: "";
        background-image: url('https://api.iconify.design/mdi:checkbox-blank-outline.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        display: block;
        height: 100%;
        width: 100%;
      }

      &:checked {
        &:before {
          content: "";
          background-image: url('https://api.iconify.design/mdi:checkbox-marked-outline.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          display: block;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
