.debug {
    display: block;
    position: fixed;
    top: 0;
    height: 8px;
    width: 100vw;
    z-index: 1;

    @media print {
        display: none;
    }

    &:before {
        background: repeating-linear-gradient(45deg, #bfbfbf 0px, #bfbfbf 16px, #8c8c8c 16px, #8c8c8c 32px);
        content: "";
        display: block;
        height: 100%;
        width: 100%;
    }
}
